import * as React from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import RichText from "./ClientListFooter/RichText";
import LinkWithTransition from "./LinkWithTransition";

const ClientListFooter = () => {
    return (
        <StaticQuery
            query={graphql`
            query ClientListFooterQuery {
              data: contentfulCaseListFooter {
                id
                content {
                  raw
                }
                title
              }
            }
      `}
            render={({data}) => {
                return (
                    <div className={`o-section o-layout--full-page c-client-list o-layout u-columns-24`}>
                        <div className={"u-colspan-8 u-hidden@desk"}>
                            <div className={"c-client-list--large-title"}>Er<br/>is</div>
                        </div>
                        <div className={"u-colspan-16 u-hidden@desk c-client-list--align-end"}>
                            <div
                                className={"c-client-list--large-title c-client-list--right"}>Altijd<br/>meer</div>
                        </div>


                        <div className={"u-colspan-1 u-colspan-4@desk"}>
                            <div className={"u-hidden@until-desk c-client-list--large-title"}>Er<br/>is</div>
                        </div>
                        <div className={"u-colspan-22 u-colspan-12@desk u-colspan-14@wall"}>
                            <div className={"c-client-list--title"}>
                                {data.title}
                            </div>
                            <div className={"o-layout u-colspan-24 c-client-list--list"}>
                                <RichText json={JSON.parse(data.content.raw)}/>
                            </div>

                            <LinkWithTransition
                                to={"/cta"} className={"c-button c-button--yellow"}>
                                ZIN IN EEN GESPREK?
                            </LinkWithTransition>
                        </div>
                        <div className={"u-colspan-1 u-colspan-8@desk u-colspan-6@wall c-client-list--align-end"}>
                            <div
                                className={"u-hidden@until-desk c-client-list--large-title c-client-list--right"}>Altijd<br/>meer</div>
                        </div>
                    </div>
                )
            }}
        />
    )
};

export default ClientListFooter;
