import * as React from "react";
import {graphql} from "gatsby";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import MediaBlock from "../components/MediaBlock";
import Breadcrumb from "../components/Breadcrumb";
import {off, on, trigger} from "../components/Events";
import Fade from "../transitions/Fade";
import {getPageTitle} from "../components/Helpers/Meta";
import Helmet from 'react-helmet';
import {useEffect, useState} from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ScrollIndicator from "../components/ScrollIndicator";
import ClientListFooter from "../components/ClientListFooter";
import {getBackgroundColorFromName} from "../components/Helpers/Color";
import LinkWithTransition from "../components/LinkWithTransition";


export const CASES_OVERVIEW_QUERY = graphql`
query CaseOverviewPageQuery($id: String!) {
  content: contentfulCaseOverviewPage(id: {eq: $id}) {
    metatitle
    cases {
        id
        metatitle
        title
        slug
        mainPhoto {
          file {
            contentType
            url
          }
        }
        hoofdFotoVideoPlaceholder {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
    }
  }
}
`;

const CasesOverviewTemplate = ({data}) => {

    const {
        content: {
            cases: cases
        }
    } = data;


    const hidePage = () => {
        Fade.animate([
            { target: '.c-cases--grid', type: Fade.TYPE_OPACITY, delay: 0.2 },
            { target: '.c-cases--title', type: Fade.TYPE_MOVE },
            { target: '.o-section', type: Fade.TYPE_OPACITY, delay: 0.6 },
            { target: '.c-logo', type: Fade.TYPE_OPACITY },
            { target: '.c-breadcrumbs', type: Fade.TYPE_OPACITY },
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 100)}, []);

    return (
        <>
            <Helmet>
                <title>{getPageTitle({title: 'Werk'})}</title>
                <style>
                    {`body { background-color: ${getBackgroundColorFromName('wit')} }`}
                </style>
            </Helmet>
            <Logo state={Logo.WHITE} />
            <Navigation />
            <ScrollIndicator />
            <Breadcrumb text={"Werk"} />

            <div className={`o-section ${isLoaded ? 'e-active--delay' : ''}`}>
                <div className={"c-cases--title"}>
                    <h1 className={" u-font-family--secondary u-font-weight--semi-bold e-effect e-fade e-move--up-gentle"}>De parels van het zuiden</h1>
                </div>

                <div className={"c-cases--spacer"} />

                <div className={"o-layout o-layout--full-page@desk u-columns-24 c-cases--grid"}>
                    {cases.map((caseItem) => {
                        return (
                            <LinkWithTransition to={`/case/${caseItem.slug}`} className={"u-colspan-24 u-colspan-12@lap c-cases--grid-item"} key={caseItem.slug}>
                                <div className={"c-cases--grid-case__title u-font-family--secondary u-text-color--white "}>
                                    {caseItem.metatitle}
                                </div>
                                <LazyLoadComponent>
                                    <MediaBlock
                                        item={caseItem.mainPhoto} additionalClasses={"o-object-fit--cover media"}
                                        placeholder={caseItem.hoofdFotoVideoPlaceholder}/>
                                </LazyLoadComponent>
                            </LinkWithTransition>
                        )
                    })}
                </div>
            </div>

            <ClientListFooter/>
        </>
    )
};

export default CasesOverviewTemplate;
