import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import LinkWithTransition from "../LinkWithTransition";
import catchInternalUrl from "../Util/catchInternalUrl";

const options = () => ({
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => (
            <ul>{children}</ul>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
            const { uri } = node.data
            const internalLink = catchInternalUrl(uri)
            const Tag = internalLink ? LinkWithTransition : 'a'

            return (
                <Tag
                    to={internalLink}
                    href={internalLink ? null : uri}
                    target={internalLink ? null : '_blank'}
                >
                    {children}
                </Tag>
            )
        },
    }
});

const RichText = ({ json, extraClasses }) => (
    <div className={`c-editor-content u-text-retain ${extraClasses || ''}`}>
        {documentToReactComponents(json, options())}
    </div>
);

export default RichText
